import {Col, Form, Input, Select} from "antd";
import React, {CSSProperties, FunctionComponent} from "react";
import {selectOption} from "../pages/users/__generated__/selectOption";
import {listProcessCodes} from "../pages/documents/__generated__/listProcessCodes";
import {getDocumentStatus} from "../pages/documents/__generated__/getDocumentStatus";
import {getDocumentFormat} from "../pages/documents/__generated__/getDocumentFormat";
import {getDocumentType} from "../pages/documents/__generated__/getDocumentType";
import {getI18n} from "../utils/Utils";
import {useIntl} from "react-intl";
import {selectOptionCoCodeEntitlement} from "../pages/users/__generated__/selectOptionCoCodeEntitlement";

export type FormItem = {
  type?: any;
  label: string;
  name: string;
  placeHolder?: string;
  width?: number;
  height?: number;
  colSpan?: number;
  value?: string;
  selectOption?: selectOption;
  getDocumentType?: getDocumentType;
  listProcessCodes?: listProcessCodes;
  getDocumentStatus?: getDocumentStatus;
  getDocumentFormat?: getDocumentFormat;
  onChange?: any;
  hidden?: boolean;
  disabled?: boolean;
  required?: boolean;
  infoOutlined?: string;
  defaultValue?: string;
  onSelect?: any;
};


export type FormItem2 = {
  type?: any;
  label: string;
  name: string;
  placeHolder: string;
  width?: number;
  colSpan?: number;
  value?: string;
  onChange?: any;
  listProcessCodes?: listProcessCodes;
  getDocumentStatus?: getDocumentStatus;
  getDocumentFormat?: getDocumentFormat;
  getDocumentType?: getDocumentType;
  defaultValue?: string;
  style?: CSSProperties;
};

export type FormItem3 = {
    type?: any;
    label: string;
    name: string;
    placeHolder?: string;
    width?: number;
    height?: number;
    colSpan?: number;
    value?: string;
    selectOptionCoCodeEntitlement?: selectOptionCoCodeEntitlement;
    getDocumentType?: getDocumentType;
    listProcessCodes?: listProcessCodes;
    getDocumentStatus?: getDocumentStatus;
    getDocumentFormat?: getDocumentFormat;
    onChange?: any;
    hidden?: boolean;
    disabled?: boolean;
    required?: boolean;
    defaultValue?: string;
};


export type FormDatePickerItem = {
  rules?: boolean;
  label: string;
  name: string;
  placeHolder?: string;
  width?: number;
  colSpan?: number;
  onChange: any;
};
const { Option } = Select;


export const PortalFormItem: FunctionComponent<FormItem> = ({ label, name, placeHolder, width, colSpan, onChange, hidden, disabled, required, infoOutlined, value }) => {
  return (
    <Col span={colSpan || 5}>
      <Form.Item name={name} key={name} label={label} hidden={hidden} required={required} tooltip={infoOutlined || ''}>
        <Input placeholder={placeHolder} allowClear={true} data-testid={name} onChange={onChange} disabled={disabled} width={width} defaultValue={value} />
      </Form.Item>
    </Col>
  );
};

export const PortalFormItemSelect: FunctionComponent<FormItem> = ({
                                                                    label,
                                                                    name,
                                                                    placeHolder,
                                                                    colSpan,
                                                                    selectOption,
                                                                    type,
                                                                    onSelect
                                                                  }) => {
  
  return (
    <Col span={colSpan || 5}>
      <Form.Item name={name} key={name} label={label}>
        <Select placeholder={placeHolder} mode={type} allowClear={true}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                  option.props.children.toUpperCase().indexOf(input.toUpperCase()) >= 0
                }
                optionFilterProp="children"
                showSearch={true}
                data-testid={name}
                onSelect={onSelect}

        >
          {selectOption && selectOption.getSelectDropDown &&
          selectOption.getSelectDropDown.map((item) => (
            <Option key={`${item.id}_${item.name}`} value={item.value} data-testid={item.value}>
              {item.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Col>
  );
};

export const PortalFormItemSelectEcapCode: FunctionComponent<FormItem> = ({
                                                                            label,
                                                                            name,
                                                                            placeHolder,
                                                                            colSpan,
                                                                            selectOption,
                                                                            type
}) => {
  return (
    <Col span={colSpan || 5}>
        <Form.Item name={name} key={name} label={label}>
                    <Select placeholder={placeHolder} mode={type} allowClear={true}
                    filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    option.props.children.toUpperCase().indexOf(input.toUpperCase()) >= 0
                    }
                    optionFilterProp="children"
                    showSearch={true}
                    data-testid={name}
                    >
                    {selectOption && selectOption.getSelectDropDown &&
                    selectOption.getSelectDropDown.map((item) => (
                    <Option key={`${item.id}_${item.name}`} value={item.id} data-testid={item.id}>
                    {item.name}
                    </Option>
                    ))}
                    </Select>
      </Form.Item>
    </Col>
);
};

export const PortalFormItemSelectEcapCodeUsingEntitlement: FunctionComponent<FormItem3> = ({
                                                                              label,
                                                                              name,
                                                                              placeHolder,
                                                                              colSpan,
                                                                              selectOptionCoCodeEntitlement,
                                                                              type,
                                                                              value
                                                                          }) => {
    return (
        <Col span={colSpan || 5}>
            <Form.Item name={name} key={name} label={label}>
                <Select placeholder={placeHolder} mode={type} allowClear={true}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                            option.props.children.toUpperCase().indexOf(input.toUpperCase()) >= 0
                        }
                        optionFilterProp="children"
                        showSearch={true}
                        data-testid={name}
                        defaultValue={value}
                >
                    {selectOptionCoCodeEntitlement && selectOptionCoCodeEntitlement.getSelectDropDownFromCoCodeEntitlement &&
                        selectOptionCoCodeEntitlement.getSelectDropDownFromCoCodeEntitlement.map((item) => (
                            <Option key={`${item.id}_${item.name}`} value={item.id} data-testid={item.id}>
                                {item.name}
                            </Option>
                        ))}
                </Select>
            </Form.Item>
        </Col>
    );
};

export const PortalFormItemSelectEcap: FunctionComponent<FormItem2> = ({
                                                                         label,
                                                                         name,
                                                                         placeHolder,
                                                                         colSpan,
                                                                         width,
                                                                         listProcessCodes,
                                                                         type,
                                                                         value,
                                                                         style,
                                                                       }) => {
  const intl = useIntl();
  return (
    <Col span={colSpan || 3}>
      <Form.Item name={name} key={name} /*rules={[{ required: true}]}*/ label={label}>
        <Select placeholder={placeHolder} mode={type} allowClear={true}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                  option.props.children.toUpperCase().indexOf(input.toUpperCase()) >= 0
                }
                optionFilterProp="children"
                showSearch={true}
                data-testid={name} 
                defaultValue={value}
                style={style || { width: 265 }}>
          {listProcessCodes && listProcessCodes.listProcessCodes &&
          listProcessCodes.listProcessCodes.map((item) => (
            <Option key={`${item.id}_${item.processCode}`} value={item.id} data-testid={item.processCode}>
              {item.processCode.concat(" - ").concat(getI18n(item.phraseId, item.processCodeName, intl))}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Col>
  );
};

export const PortalFormItemSelectStatus: FunctionComponent<FormItem2> = ({
                                                                           label,
                                                                           name,
                                                                           placeHolder,
                                                                           colSpan,
                                                                           width,
                                                                           getDocumentStatus,
                                                                           type

                                                                         }) => {
  return (
    <Col span={colSpan || 3}>
      <Form.Item name={name} key={name} /*rules={[{ required: true}]}*/ label={label}>
        <Select placeholder={placeHolder} mode={type} allowClear={true}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                  option.props.children.toUpperCase().indexOf(input.toUpperCase()) >= 0
                }
                optionFilterProp="children"
                showSearch={true}
                data-testid={name}
          //style={{ width: 230 }}

        >
          {getDocumentStatus && getDocumentStatus.getDocumentStatus &&
          getDocumentStatus.getDocumentStatus.map((item) => (
            <Option key={`${item.documentStatus}_${item.documentStatus}`} value={item.id} data-testid={item.id}>
              {item.documentStatus}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Col>
  );
};

export const PortalFormItemSelectDocType: FunctionComponent<FormItem2> = ({
                                                                            label,
                                                                            name,
                                                                            placeHolder,
                                                                            colSpan,
                                                                            width,
                                                                            getDocumentType,
                                                                            type,
                                                                            value,
                                                                            style
                                                                          }) => {
  return (
    <Col span={colSpan || 5}>
      <Form.Item name={name} key={name} label={label}>
        <Select placeholder={placeHolder} mode={type} allowClear={true}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                  option.props.children.toUpperCase().indexOf(input.toUpperCase()) >= 0
                }
                optionFilterProp="children"
                showSearch={true}
                data-testid={name}
                style={style}
                defaultValue={value}>

          {getDocumentType && getDocumentType.getDocumentType &&
          getDocumentType.getDocumentType.map((item) => (
            <Option key={`${item.id}_${item.documentType}`} value={item.id} data-testid={item.id}>
              {item.documentType}
            </Option> ))}
        </Select>
      </Form.Item>
    </Col>
  );
};

export const PortalFormItemFsmSelectDocType: FunctionComponent<FormItem2> = ({
                                                                              label,
                                                                              name,
                                                                              placeHolder,
                                                                              colSpan,
                                                                              width,
                                                                              getDocumentType,
                                                                              type
                                                                          }) => {
    return (
        <Col span={colSpan || 5}>
            <Form.Item name={name} key={name} label={label}>
                <Select placeholder={placeHolder} mode={type} allowClear={true}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                            option.props.children.toUpperCase().indexOf(input.toUpperCase()) >= 0
                        }
                        optionFilterProp="children"
                        showSearch={true}
                        data-testid={name}>
                    {getDocumentType && getDocumentType.getDocumentType &&
                        getDocumentType.getDocumentType.map((item) => (
                            <Option key={`${item.id}_${item.documentType}`} value={item.id} data-testid={item.id}>
                                {item.documentType.split("-")[0]}
                            </Option> ))}
                </Select>
            </Form.Item>
        </Col>
    );
};

export const PortalFormItemSelectFormat: FunctionComponent<FormItem> = ({
                                                                          label,
                                                                          name,
                                                                          placeHolder,
                                                                          colSpan,
                                                                          width,
                                                                          getDocumentFormat,
                                                                          type,
                                                                          value
                                                                        }) => {
  return (
    <Col span={colSpan || 2}>
      <Form.Item name={name} key={name} label={label} /*rules={[{ required: true}]}*/>
        <Select placeholder={placeHolder} mode={type} allowClear={true}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                  option.props.children.toUpperCase().indexOf(input.toUpperCase()) >= 0
                }
                optionFilterProp="children"
                showSearch={true}
                data-testid={name}
                style={{ width }}
                defaultValue={value}>
          {getDocumentFormat && getDocumentFormat.getDocumentFormat &&
          getDocumentFormat.getDocumentFormat.map((item) => (
            <Option key={`${item.id}_${item.documentFormat}`} value={item.id} data-testid={item.id}>
              {item.documentFormat}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Col>
  );
};
/*

export const PortalFormItemDatePicker: FunctionComponent<FormDatePickerItem> = ({
                                                                                  label,
                                                                                  name,
                                                                                  placeHolder,
                                                                                  width,
                                                                                  colSpan,
                                                                                  rules
                                                                                }: FormDatePickerItem) => {
  return (
    <Col span={5}>
      <Form.Item name={name} key={name} label={label}>
        <DatePicker
          format={"YYYY-MM-DD"}
          placeholder={placeHolder}
          allowClear={true}
          data-testid={name}
          style={{ width: "100%" }}
        />
      </Form.Item>
    </Col>
  );
};

export const PortalFormItemDatePickerEcap: FunctionComponent<FormDatePickerItem> = ({
                                                                                  label,
                                                                                  name,
                                                                                  placeHolder,
                                                                                  width,
                                                                                  colSpan
              }: FormDatePickerItem) => {
              return (
              <Col span={colSpan || 5}>
              <label>{label}</label>
              <Form.Item name={name} key={name}>
              <DatePicker
                      className="mb20 mt8"
                      format={"YYYY-MM-DD"}
                      placeholder={placeHolder}
                      style={{ width: 420 }}
                      allowClear={true}
                      data-testid={name}
                      onKeyDown={(e) => {e.preventDefault();}}
                      //disabledDate={(current) => current.isBefore(dayjs().subtract(3, "months")) || current.isAfter(dayjs().subtract(0, "day"))}
                       disabledDate={(current) => current.isAfter(dayjs().subtract(0, "day"))}
              />
              </Form.Item>
              </Col>
              );
};

export const PortalFormItemDatePickerEcapDraft: FunctionComponent<FormDatePickerItem> = ({
  label,
  name,
  placeHolder,
  width,
  colSpan
}: FormDatePickerItem) => {
return (

);
};

export const PortalFormItemDatePickerEcapRec: FunctionComponent<FormDatePickerItem> = ({
                                                                                    label,
                                                                                    name,
                                                                                    placeHolder,
                                                                                    width,
                                                                                    colSpan
          }: FormDatePickerItem) => {
          return (
                        <Col span={colSpan || 5}>
                        <label>{label}</label>
                        <Form.Item name={name} key={name} rules={[{required: true, message: 'Please Select Reception Date From'}]}>
                        <DatePicker
                        defaultValue={dayjs()}
                        className="mb20 mt8"
                        format={"YYYY-MM-DD"}
                        placeholder={placeHolder}
                        style={{ width: 420 }}
                        allowClear={true}
                        data-testid={name}
                        onKeyDown={(e) => {e.preventDefault();}}
                        disabledDate={(current) => current.isBefore(dayjs().subtract(3, "months")) || current.isAfter(dayjs().subtract(0, "day"))}
                        />
          </Form.Item>
          </Col>
          );
};

export const PortalFormItemDatePickerEcapReception: FunctionComponent<FormDatePickerItem> = ({
                                                                                            label,
                                                                                            name,
                                                                                            placeHolder,
                                                                                            width,
                                                                                            colSpan
                      }: FormDatePickerItem) => {
                      return (

                      );
};

export const PortalFormItemDatePickerEcapReceptionDraft: FunctionComponent<FormDatePickerItem> = ({
  label,
  name,
  placeHolder,
  width,
  colSpan
}: FormDatePickerItem) => {
return (
<Col span={colSpan || 5}>
<label>{label}</label>
<Form.Item name={name} key={name} rules={[{ required: true, message: 'Please Select Document Date To' }]}>
<DatePicker 
defaultValue={dayjs()}
className="mb20 mt8"
format={"YYYY-MM-DD"}
placeholder={placeHolder}
style={{ width: 400 }}
allowClear={true}
data-testid={name}
onKeyDown={(e) => {e.preventDefault();}}
disabledDate={(current) => current.isAfter(dayjs().subtract(0, "day"))}
/>
</Form.Item>
</Col>
);
};

export const PortalFormItemDatePickerEcapReceptionOverview: FunctionComponent<FormDatePickerItem> = ({
                                                                                          label,
                                                                                          name,
                                                  PortalFormItemDatePickerEcap                                        placeHolder,
                                                                                          width,
                                                                                          colSpan
}: FormDatePickerItem) => {
return (
               <Col span={colSpan || 5}>
                        <label>{label}</label>
                        <Form.Item name={name} key={name} rules={[{ required: true, message: 'Please Select Reception Date To' }]}>
                                      <DatePicker
                                      defaultValue={dayjs()}
                                      className="mb20 mt8"
                                      format={"YYYY-MM-DD"}
                                      placeholder={placeHolder}
                                      style={{ width: 420 }}
                                      allowClear={true}
                                      data-testid={name}
                                      onKeyDown={(e) => {e.preventDefault();}}
                                      disabledDate={(current) => current.isAfter(dayjs().subtract(0, "day")) || current.isBefore(dayjs().subtract(3, "months"))}
                                      />
                          </Form.Item>
                          </Col>
);
};
*/

export const PortalFormItemTwoSelect: FunctionComponent<FormItem> = ({
                                                                      label,
                                                                       name,
                                                                       placeHolder,
                                                                       width,
                                                                       colSpan,
                                                                       selectOption,
                                                                       type
                                                                     }: FormItem) => {
  return (
    <Col span={colSpan || 5}>
      <label>{label}</label>
      <Form.Item name={name}>
        <Select placeholder={placeHolder} mode={type} data-testid={name}>
          {selectOption && selectOption.getSelectDropDown &&
          selectOption.getSelectDropDown.map((item) => (
            <Option key={`${item.name}`} value={item.id}>
              {item.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Col>
  );
};
export const PortalFormItemSelectBMWCompany: FunctionComponent<FormItem> = ({
                                                                              label,
                                                                              name,
                                                                              placeHolder,
                                                                              colSpan,
                                                                              width,
                                                                              selectOption,
                                                                              type
                                                                            }) => {
  return (
    <Col span={colSpan || 5}>
      <label>{label}</label>
      <Form.Item name={name} key={name} /*rules={[{ required: true}]}*/>
        <Select placeholder={placeHolder} mode={type} allowClear={true}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                  option.props.children.toUpperCase().indexOf(input.toUpperCase()) >= 0
                }
                optionFilterProp="children"
                showSearch={true}
                data-testid={name}
                style={{ width: 230 }}

        >
          {selectOption && selectOption.getSelectDropDown &&
          selectOption.getSelectDropDown.map((item) => (
            <Option key={`${item.id}_${item.name}`} value={item.value} data-testid={item.value}>
              {item.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Col>
  );
};
