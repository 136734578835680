import React from "react";
import {Tag} from "antd";
import {renderAsDateTime} from "./Users";
import {IntlShape} from "react-intl/src/types";

export const USER_TABLE_HEADERS = [
    {
        headerName: "Login Name",
        field: "username",
        colId: "username",
        width: 205,
        checkboxSelection: true,
        filter: "agTextColumnFilter"
    },
    {
        headerName: "First Name",
        field: "firstname",
        colId: "firstname",
        filter: "agTextColumnFilter",
        width: 250
    },
    {
        headerName: "Last Name",
        field: "lastname",
        colId: "lastname",
        filter: "agTextColumnFilter",
        width: 250
    },
    {
        headerName: "User Email",
        field: "email",
        colId: "email",
        filter: "agTextColumnFilter",
        width: 400
    },
    {
        headerName: "From Date",
        field: "insert_time",
        colId: "insert_time",
        cellRendererFramework: (props) => renderAsDateTime(props.value),
        width: 200,
        filter: false
    },
    {
        headerName: "Role",
        field: "roleName",
        colId: "roleName",
        filter: "agSetColumnFilter",
        filterParams: {
            values: ["SystemAdmin",
                "system_Pending",
                "use_eDocument_ADMIN",
                "use_eDocument_USER"],
            debounceMs: 2000
        },
        width: 160
    },
    {
        headerName: "Action",
        filter: false,
        cellRenderer: "btnUserActionCellRenderer",
        width: 200
    }
];

export const PARTNER_TABLE_HEADERS = [
    {
        headerName: "Partner Number",
        field: "partnerNo",
        colId: "partnerNo",
        width: 300
    },
    {
        headerName: "First Name",
        field: "partnerName",
        colId: "partnerName",
        width: 400
    },
    {
        headerName: "Category",
        field: "category",
        colId: "category",
        width: 400
    }

];

export const PROCESS_TABLE_HEADERS = [
    {
        headerName: "Process Code",
        field: "processCode",
        colId: "processCode",
        width: 300
    },
    {
        headerName: "Process Code Description",
        field: "processName",
        colId: "processName",
        width: 400
    }
];

export function COMPANY_TABLE_HEADERS(intl: IntlShape) {
    return [
        {
            headerName: intl.formatMessage({id: 'user-profile-grid-partner-no'}),
            field: "partnerNo",
            colId: "partnerNo",
            width: 300
        },
        {
            headerName: intl.formatMessage({id: 'user-profile-grid-partner-name'}),
            field: "partnerName",
            colId: "partnerName",
            width: 300
        },
        {
            headerName: intl.formatMessage({id: 'user-profile-grid-category'}),
            field: "category",
            colId: "category",
            width: 300
        },
        {
            headerName: intl.formatMessage({id: 'user-profile-grid-company-code'}),
            field: "companies",
            colId: "companies",
            width: 500,
            cellRenderer: (props) => props.value.map((item) => { return (
                <Tag style={{margin: '2px'}} key={item.companyCode}>
                    {item.companyCode}
                </Tag>
            )})
        },


    ];
}

export function COMPANY_TABLE_HEADERS_WITH_DETAILS(intl: IntlShape) {
    return [
        {
            headerName: intl.formatMessage({id: 'user-profile-grid-partner-no'}),
            field: "partnerNo",
            colId: "partnerNo",
            width: 300,
            cellRenderer: 'agGroupCellRenderer'
        },
        {
            headerName: intl.formatMessage({id: 'user-profile-grid-partner-name'}),
            field: "partnerName",
            colId: "partnerName",
            width: 300
        },
        {
            headerName: intl.formatMessage({id: 'user-profile-grid-category'}),
            field: "category",
            colId: "category",
            width: 300
        },
        {
            headerName: intl.formatMessage({id: 'user-profile-grid-company-code'}),
            field: "companies",
            colId: "companies",
            width: 500,
            cellRenderer: (props) => props.value.map((item) => { return (
                <Tag style={{margin: '2px'}} key={item.companyCode}>
                    {item.companyCode}
                </Tag>
            )})
        },


    ];
}

export function COMPANY_TABLE_HEADERS_WITH_PARTNER_TYPE(intl: IntlShape) {
    return [
        {
            headerName: intl.formatMessage({id: 'user-profile-grid-partner-no'}),
            field: "partnerNo",
            colId: "partnerNo",
            width: 300,
            cellRenderer: 'agGroupCellRenderer'
        },
        {
            headerName: intl.formatMessage({id: 'user-profile-grid-partner-name'}),
            field: "partnerName",
            colId: "partnerName",
            width: 300
        },
        {
            headerName: intl.formatMessage({id: 'user-profile-grid-category'}),
            field: "category",
            colId: "category",
            width: 300
        },
        {
            headerName: intl.formatMessage({id: 'user-profile-grid-partner-type'}),
            field: "partnerNumberType",
            colId: "partnerNumberType",
            width: 200
        },
        {
            headerName: intl.formatMessage({id: 'user-profile-grid-company-code'}),
            field: "companies",
            colId: "companies",
            width: 500,
            cellRenderer: (props) => props.value.map((item) => { return (
                <Tag style={{margin: '2px'}} key={item.companyCode}>
                    {item.companyCode}
                </Tag>
            )})
        },


    ];
}

export enum UserType {
    SYSTEM_ADMIN = "SYSTEMADMIN",
    PARTNER_USER = "use_eDocument_USER",
    PARTNER_ADMIN = "use_eDocument_ADMIN",
}

export const removeDuplicates = prop => list =>
    Array.from(
        list
            .reduce(
                (acc, item) => (
                    item && item[prop] && acc.set(item[prop], item),
                        acc
                ),
                new Map()
            )
            .values()
    );

