import { Button, Card, Col, Input, message, Radio, Row, Select, Spin } from 'antd';
import { Form } from 'antd/lib';
import React, { useEffect, useState } from 'react';
import {
    ADMIN_ENTITLEMENT,
    DropDownSelectType,
    EntitlementFormProps,
} from './EntitlementInterfaces';
import ObjectClassComponent from './ObjectClassDropDown';
import CompanyCode from './CompanyCodeDropDown';
import { UpdateEntitlementMutationVariables, useUpdateEntitlementMutation } from './mutations.generated';
import { EntitlementRule, EntitlementRuleInput } from 'src/__generated__/types';
import { useGetAllEntitlementsQuery } from './queries.generated';
import EntitlementGroupDropDown from './EntitlementGroupDropDown';
import EntitlementAdvanceGroups from './EntitlementAdvanceGroups';
import RenderIfEntitled from '../../../components/EntitlementComponents';
import { isArray } from 'lodash';
import { useAppDispatch } from '../../../main/hooks';
import { updateNewEntitlement } from './redux/IStoredEntitlementsSlice';
import { getI18n } from '../../../utils/Utils';
import { ClearOutlined, SaveOutlined } from '@ant-design/icons';

const CreateEntitlement: React.FunctionComponent<EntitlementFormProps> = (props) => {
    const [form] = Form.useForm();
    const [objectClassSelected, setObjectClassSelected] = useState<string[]>([]);
    const [entitlementRule, setEntitlementRule] = useState<EntitlementRule>(undefined);
    const [departmentCodes, setDepartmentCodes] = useState<string[]>([]);
    const [userIdList, setUserIdList] = useState<string[]>([]);
    const [partnerIdList, setPartnerIdList] = useState<string[]>([]);
    const dispatch = useAppDispatch();
    const [advanceGroupUpdated, setAdvanceGroupUpdated] = useState<boolean>(false);

    const [updateEntitlementMutation, { data: updatedEntitlements, loading: updateLoading, error }] =
        useUpdateEntitlementMutation({
            variables: {
                entitlementRule: {} as EntitlementRuleInput,
            },
        });

    const {
        data: entitlementTypes,
        loading: entitlementTypesLoading,
        error: entitlementTypesError,
    } = useGetAllEntitlementsQuery({
        variables: {},
    });

    if (entitlementTypesError) message.error(entitlementTypesError);

    function clearForm() {
        console.log("Clear all fields");
        form.resetFields();
    }

    const handleFormSubmit = (values) => {
        // Handle form submission logic here
        //call mutation updateEntitlements
        if (!entitlementRule) {
            message.error('Nothing to update');
            return;
        }
        const request: EntitlementRuleInput = { ...entitlementRule };
        updateEntitlementMutation({
            variables: { entitlementRule: request } as UpdateEntitlementMutationVariables,
        })
            .then((response) => {
                //Update redux with the updated record so that we can later refresh the Entitlement Search Grid with the new values
                dispatch(updateNewEntitlement(response.data.updateEntitlement));
                if (props?.entitlementRuleToEdit?.ruleId) {
                    message.success(
                        getI18n(
                            'entitlement-edit-success-update-message',
                            'Entitlement updated successfully',
                            props.intl
                        )
                    );
                    props.onCancel();
                } else {
                    message.success(
                        getI18n(
                            'entitlement-edit-success-create-message',
                            'Entitlement created successfully',
                            props.intl
                        )
                    );
                }
                clearForm();
            })
            .catch((error) => {
                console.error('Error:', error);
                message.error(error);
            });
    };

    const convertB2XRoles = () => {
        const b2xRoles: string[] = [];
        setObjectClassSelected(form.getFieldValue('objectClass'));
        if (
            form.getFieldValue('objectClass')?.includes('BMWexternAccount') ||
            form.getFieldValue('objectClass')?.includes('BMWperson') ||
            form.getFieldValue('objectClass')?.includes('BMWSupplierPerson')
        ) {
            b2xRoles.push('use_eDocument_financial_editor');
            b2xRoles.push('use_eDocument_financial_viewer');
        }
        if (form.getFieldValue('objectClass')?.includes('BMWPartnerUser')) {
            b2xRoles.push('use_eDocument_Portal_Financial_Editor');
        }
        if (b2xRoles.length === 0) {
            b2xRoles.push('ANY');
        }
        return b2xRoles;
    };

    const convertDepartmentCodes = () => {
        let departmentCodes: string = form.getFieldValue('departmentCodes')?.toString();
        if (
            (!form.getFieldValue('objectClass')?.includes('BMWexternAccount') &&
                !form.getFieldValue('objectClass')?.includes('BMWperson') &&
                !form.getFieldValue('objectClass')?.includes('ANY')) ||
            !departmentCodes ||
            departmentCodes === ''
        ) {
            departmentCodes = 'ANY';
        }
        return departmentCodes;
    };

    const convertEntitlementGroup = () => {
        const entitlementGroup: string[] = [];
        if (form?.getFieldValue('advanceSelectedEntitlements')?.length > 0) {
            form.getFieldValue('advanceSelectedEntitlements').map((entitlement) => {
                entitlementGroup.push(entitlement);
            });
        } else {
            entitlementGroup.push('Group : '.concat(form.getFieldValue('entitlementGroup')));
        }
        return entitlementGroup;
    };

    const formatRuleDescription = (
        departmentCodes: string,
        entitlementGroup: string[],
        b2xRoles: string[],
        userId: string,
        partner: string
    ) => {
        form.setFieldValue(
            'ruleDescription',
            form
                .getFieldValue('access')
                ?.toString()
                .toUpperCase()
                .concat(' [ ', form?.getFieldValue('objectClass'), ' ]')
                .concat(' in DEPARTMENTS [ ', departmentCodes, ' ]')
                .concat(' ( ', form?.getFieldValue('accessLevel')?.toString().toUpperCase(), ' )')
                .concat(' access to [ ', entitlementGroup?.toString(), ' ]')
                .concat(', if they have any one of the following B2X assigned roles [ ', b2xRoles.toString(), ' ]')
                .concat(' on ', partner)
                .concat(
                    ' in COMPANIES [ ',
                    form?.getFieldValue('companyCode')?.toString(),
                    ' ]'.concat(' for USER ID [ ', userId, ' ]')
                )
        );
    };

    const convertCompanyCodeSQL = () => {
        let companyCodeSQL: string = form.getFieldValue('companyCode')?.toString().replace(/,/g, ';');
        companyCodeSQL = companyCodeSQL === 'ANY' ? '*' : companyCodeSQL;
        companyCodeSQL = companyCodeSQL === undefined || companyCodeSQL === '' ? '*' : companyCodeSQL;
        return companyCodeSQL;
    };

    const convertB2XRolesSQL = (b2xRoles: string[]) => {
        let b2xRoleSQL: string = b2xRoles.toString().replace(/,/g, ';');
        b2xRoleSQL = b2xRoleSQL === 'ANY' ? '*' : b2xRoleSQL;
        b2xRoleSQL = b2xRoleSQL === undefined || b2xRoleSQL === '' ? '*' : b2xRoleSQL;
        return b2xRoleSQL;
    };

    const convertDepartmentCodesSQL = () => {

        let departmentCodeList = form.getFieldValue("departmentCodes")
        if (isArray(departmentCodeList) && departmentCodeList.length > 0)
        {
            departmentCodeList = Array.from(new Set(departmentCodeList.map((departmentCode) => departmentCode.toUpperCase())));
            setDepartmentCodes(departmentCodeList);
            // console.log("departmentCodeList", departmentCodeList);
            //create departmentCodesSQL by converting array departmentCodes to string delimited by ;
            let departmentCodesSQL: string = departmentCodeList.join(';');
             //if the word ANY appear anywhere in the string then replace it with *
             departmentCodesSQL = departmentCodesSQL.replace("ANY", '*');
            return departmentCodesSQL;
        }
        return "*";
    };

    const convertUserIdSQL = () => {
         let userIdList = form.getFieldValue("userId")
        if (isArray(userIdList) && userIdList.length > 0)
        {
            userIdList = Array.from(new Set(userIdList.map((userId) => userId.toUpperCase())));
            setUserIdList(userIdList);
            let userIdListSQL: string = userIdList.join(';');
             //if the word ANY appear anywhere in the string then replace it with *
             userIdListSQL = userIdListSQL.replace("ANY", '*');
            return userIdListSQL;
        }
        return "*";
    }

    const convertPartnerSQL = () => {
        let partnerIdList = form.getFieldValue("partner")
        if (isArray(partnerIdList) && partnerIdList.length > 0)
        {
            partnerIdList = Array.from(new Set(partnerIdList.map((partnerId) => partnerId.toUpperCase())));
            setPartnerIdList(partnerIdList);
            let partnerIdListSQL: string = partnerIdList.join(';');
             //if the word ANY appear anywhere in the string then replace it with *
             partnerIdListSQL = partnerIdListSQL.replace("ANY", '*');
            return partnerIdListSQL;
        }
        return "*";
    }

    const convertObjectClassSQL = () => {
        let objectClassSQL: string = form.getFieldValue('objectClass')?.toString().replace(/,/g, ';');
        objectClassSQL = objectClassSQL === 'ANY' ? '*' : objectClassSQL;
        objectClassSQL = objectClassSQL === undefined || objectClassSQL === '' ? '*' : objectClassSQL;
        return objectClassSQL;
    };

    const convertAccessLevelToMode = (accessLevel: string) => {
        if (accessLevel === undefined || accessLevel === null || accessLevel === '' || accessLevel.length === 0) {
            return ['R', 'W'];
        } else if (isArray(accessLevel)) {
            const view = accessLevel.includes('view');
            const edit = accessLevel.includes('edit');
            const response = view && edit ? ['R', 'W'] : view ? ['R'] : ['W'];
            return response;
        }
        return accessLevel === 'view' ? ['R'] : ['R', 'W'];
    };

    const onValuesChange = (changedValues: any, allValues: any) => {
        // console.log("value changed", changedValues, allValues);
        setObjectClassSelected(form.getFieldValue('objectClass'));
        const b2xRoles: string[] = convertB2XRoles();
        const departmentCodes = convertDepartmentCodes();
        //if advance entitlements selected then use them else use entitlement group
        const entitlementGroup: string[] = convertEntitlementGroup();
        let userId = form.getFieldValue('userId');
        if (userId === undefined || userId === null || userId === '') {
            userId = 'ANY';
        }
        let partner = form.getFieldValue('partner');
        if (partner === undefined || partner === null || partner === '') {
            partner = 'ALL THEIR PARTNERS';
        } else {
            partner = 'the following partners [ '.concat(partner, ' ]');
        }
        //Format Rule description
        formatRuleDescription(departmentCodes, entitlementGroup, b2xRoles, userId, partner);

        const companyCodeSQL = convertCompanyCodeSQL();
        //
        const b2xRoleSQL = convertB2XRolesSQL(b2xRoles);
        const departmentCodesSQL = convertDepartmentCodesSQL();

        const objectClassSQL = convertObjectClassSQL();

        let partnerSQL = convertPartnerSQL();
        let userPermissionsRoleSQL = props?.entitlementRuleToEdit?.userPermissionRole;
        let userEnterpriseRoleSQL = props?.entitlementRuleToEdit?.userEnterpriseRole;
        let userIdSQL = convertUserIdSQL();
        if (userIdSQL === undefined || userIdSQL === null || userIdSQL === '' || userIdSQL === 'ANY') {
            userIdSQL = '*';
        }

        if (partnerSQL === undefined || partnerSQL === null || partnerSQL === '' || partnerSQL === 'ANY') {
            partnerSQL = '*';
        }
        if (
            userPermissionsRoleSQL === undefined ||
            userPermissionsRoleSQL === null ||
            userPermissionsRoleSQL === '' ||
            userPermissionsRoleSQL === 'ANY'
        ) {
            userPermissionsRoleSQL = '*';
        }
        if (
            userEnterpriseRoleSQL === undefined ||
            userEnterpriseRoleSQL === null ||
            userEnterpriseRoleSQL === '' ||
            userEnterpriseRoleSQL === 'ANY'
        ) {
            userEnterpriseRoleSQL = '*';
        }

        // find all entitlements in entitlement types that contains values in entitlement group and put into new array entitlements

        const entitlements: string[] = [];
        // console.log('Advance Entitlements', form.getFieldValue('advanceSelectedEntitlements'));
        const mode: string[] = convertAccessLevelToMode(form.getFieldValue('accessLevel'));
        if (advanceGroupUpdated && form?.getFieldValue('advanceSelectedEntitlements')?.length > 0) {
            form.getFieldValue('advanceSelectedEntitlements').forEach((entitlement) => {
                entitlementTypes.getAllEntitlements
                    .filter((entitlementType) => {
                        return (
                            entitlement === entitlementType.entitlement &&
                            (mode.length === 0 || mode.includes(entitlementType.mode.trim()))
                        );
                    })
                    .forEach((entitlementType) => {
                        if (entitlements.includes(entitlementType.entitlement))
                        {
                            entitlements.push(entitlementType.entitlement)
                        }
                    });
            });
        } else {
            form.getFieldValue('entitlementGroup')?.map((entitlementGroup) => {
                //find entitlements in entitlement
                entitlementTypes.getAllEntitlements
                    .filter((entitlement) => {
                        return (
                            entitlement?.groupId?.toLowerCase().includes(entitlementGroup?.toLowerCase()) &&
                            (mode.length === 0 || mode.includes(entitlement.mode.trim()))
                        );
                    })
                    .forEach((entitlement) => {
                        entitlements.push(entitlement.entitlement);
                    });
            });
        }
        const entitlementsSQL = entitlements.toString().replace(/,/g, ';');
        const allowOrBlockedSQL = form.getFieldValue('access')?.toString() === 'Allow' ? 'A' : 'B';

        const newRule: EntitlementRule = {
            allowFlag: allowOrBlockedSQL,
            company: companyCodeSQL,
            description: form.getFieldValue('ruleDescription'),
            entitlements: entitlementsSQL,
            partner: partnerSQL,
            userB2XRole: b2xRoleSQL,
            userDepartment: departmentCodesSQL,
            userEnterpriseRole: userEnterpriseRoleSQL,
            userId: userIdSQL,
            userObjectClass: objectClassSQL,
            userPermissionRole: userPermissionsRoleSQL,
            insertUser: props.currentUser.username,
            updateUser: props.currentUser.username,
            ruleId: props?.entitlementRuleToEdit?.ruleId,
        };
        setEntitlementRule(newRule);
        console.log('Rule:', newRule);
    };

    const processAnyInString = (fieldName) => {
        if (form?.getFieldValue(fieldName)?.includes('ANY') && form.getFieldValue(fieldName)?.length > 1) {
            const index = form.getFieldValue(fieldName)?.indexOf('ANY');

            form.getFieldValue(fieldName)?.splice(index, 1);
        }
    }

    const onAdvanceGroupChange = (fieldName, value) => {
        setAdvanceGroupUpdated(true);
        onValuesChange(fieldName, value);
    }

    const onSelect = (fieldName, value) => {
        // console.log('do select');
        // console.log('fieldName', fieldName, 'value', value);
        if (value === 'ANY' || value === 'any') {
            form.setFieldsValue({ [fieldName]: ['ANY'] });
            form.setFieldsValue({ advanceSelectedEntitlements: [] });
        } else if (value) {
            //if  ANY is present in list and list is more than 1 then remove it
            processAnyInString(fieldName);
        }
        if (fieldName === 'entitlementGroup') {
            setAdvanceGroupUpdated(false);
            //set all entitlements in the advance checklist for this group to checked
            const advanceSelectedEntitlements = form.getFieldValue('advanceSelectedEntitlements') || [];
            //find entitlements in entitlement
            const mode: string[] = convertAccessLevelToMode(form.getFieldValue('accessLevel'));
            entitlementTypes?.getAllEntitlements
                .filter((entitlement) => {
                    return (
                        entitlement?.groupId?.toLowerCase().includes(value?.toLowerCase()) &&
                        (!mode || mode.length === 0 || mode.includes(entitlement.mode.trim()))
                    );
                })
                .map((entitlement) => {
                    advanceSelectedEntitlements.push(entitlement.entitlement);
                });
            form.setFieldsValue({ advanceSelectedEntitlements });
        }

        onValuesChange(undefined, undefined);
    };

    const onAccessLevelSelect = () => {
        const advanceSelectedEntitlements = [];
        //find entitlements in entitlement
        const mode: string[] = convertAccessLevelToMode(form.getFieldValue('accessLevel'));
        form.getFieldValue('entitlementGroup')?.map((entitlementGroup) => {
            entitlementTypes?.getAllEntitlements
                .filter((entitlement) => {
                    return (
                        entitlement?.groupId?.toLowerCase().includes(entitlementGroup?.toLowerCase()) &&
                        (!mode || mode.length === 0 || mode.includes(entitlement.mode.trim()))
                    );
                })
                .map((entitlement) => {
                    advanceSelectedEntitlements.push(entitlement.entitlement);
                });
        });
        // console.dir(advanceSelectedEntitlements);
        form.setFieldsValue({ advanceSelectedEntitlements });
        onValuesChange(undefined, undefined);
    };

    props.onLoading(updateLoading || entitlementTypesLoading);

    useEffect(() => {
        if (props.entitlementRuleToEdit) {
            message.success('Entitlement rule loaded successfully');
            const accessLevel: string[] = [];
            //check if the mode in any of the entitlements is R or W and then set accessLevel accordingly
            props.entitlementRuleToEdit.entitlements.split(';').forEach((entitlement) => {
                const entitlementType = entitlementTypes?.getAllEntitlements.find((entitlementType) => {
                    return entitlementType.entitlement === entitlement;
                });
                if (entitlementType) {
                    if (entitlementType.mode.trim() === 'R') {
                        if (accessLevel.findIndex((level) => level === 'view') === -1) accessLevel.push('view');
                    } else if (entitlementType.mode.trim() === 'W') {
                        if (accessLevel.findIndex((level) => level === 'edit') === -1) accessLevel.push('edit');
                    }
                }
            });

            form.setFieldsValue({
                access: props.entitlementRuleToEdit.allowFlag === 'A' ? 'Allow' : 'Blocked',
                accessLevel,
                departmentCodes: props.entitlementRuleToEdit.userDepartment.toUpperCase().split(";"),
                objectClass: props.entitlementRuleToEdit.userObjectClass.split(';').map((objectClass) => {
                    return objectClass.trim();
                }),
                companyCode: props.entitlementRuleToEdit.company.split(';').map((companyCode) => {
                    return companyCode.trim();
                }),
                ruleDescription: props.entitlementRuleToEdit.description,
                entitlementGroup: props.entitlementRuleToEdit.groupId,
                advanceSelectedEntitlements: props.entitlementRuleToEdit.entitlements.split(';'),
                userId: props.entitlementRuleToEdit.userId.toUpperCase().split(";"),
                partner: props.entitlementRuleToEdit.partner.toUpperCase().split(";"),
            });
            setObjectClassSelected(props.entitlementRuleToEdit.userObjectClass.split(';'));
        }
    }, [props.entitlementRuleToEdit]);

    const onValueChangeToUpper = (fieldName,value) => {
        console.log("field", fieldName, "value", value);
        const upperCaseValues = value.map((val) => val.toUpperCase());
        form.setFieldsValue({ [fieldName]: upperCaseValues });
    }


    return (
            <Spin spinning={updateLoading || entitlementTypesLoading}>
                <Card>
                    <Form form={form} onFinish={handleFormSubmit} layout="vertical" onValuesChange={onValuesChange} >
                        <Row gutter={24} className={'pl20'}>
                            <Col span={1}>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" icon={<SaveOutlined />} >
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Col>
                            <Col span={1}>
                                <Form.Item>
                                    <Button type="default" onClick={clearForm} icon={<ClearOutlined />} >
                                        Clear
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24} className={'pl20'}>
                            <Col span={4}>
                                <EntitlementGroupDropDown
                                    setEntitlementGroupSelected={onSelect}
                                    onLoading={props.onLoading}
                                    dropDownSelectType={DropDownSelectType.Multiple}
                                    required={true}
                                    onGroupClear={onSelect}
                                />
                            </Col>
                            <Col span={4}>
                                <ObjectClassComponent
                                    setObjectClassSelected={onSelect}
                                    selectType={DropDownSelectType.Multiple}
                                    required={true}
                                    onLoading={props.onLoading}
                                />
                            </Col>
                            {objectClassSelected?.includes('BMWexternAccount') ||
                            objectClassSelected?.includes('BMWperson') ||
                            objectClassSelected?.includes('ANY') ? (
                                <Col span={4}>
                                    <Form.Item
                                        shouldUpdate={(prevValues, currentValues) =>
                                            prevValues.accessSources !== currentValues.accessSources
                                        }
                                    >
                                        <Col span={8}>
                                            <Form.Item name="departmentCodes" label="Department Codes" required={true} >
                                                <Select
                                                    mode="tags"
                                                    style={{ width: '100%' }}
                                                    allowClear={true}
                                                    placeholder="Enter department codes"
                                                    onChange={(value) => onValueChangeToUpper("departmentCodes",value) }
                                                    value={departmentCodes}
                                                    onSelect={(value) => onSelect("departmentCodes",value)}
                                                >
                                                    {departmentCodes.map((departmentCode) => (
                                                        <Select.Option key={departmentCode} value={departmentCode}>
                                                            {departmentCode}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Form.Item>
                                </Col>
                            ) : null}
                            <Col span={6}>
                                <CompanyCode
                                    intl={props.intl}
                                    currentUser={props.currentUser}
                                    distinctEntitlements={props.distinctEntitlements}
                                    selectType={DropDownSelectType.Multiple}
                                    onSelect={onSelect}
                                />
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    name="accessLevel"
                                    label="Access Level"
                                    rules={[{ required: true, message: 'Please select access Level' }]}
                                >
                                    <Select
                                        mode="multiple"
                                        allowClear={true}
                                        placeholder="Select View/Edit or both"
                                        style={{ width: '100%' }}
                                        onSelect={onAccessLevelSelect}
                                        onDeselect={onAccessLevelSelect}
                                    >
                                        <Select.Option key={1} value="view">
                                            View
                                        </Select.Option>
                                        <Select.Option key={2} value="edit">
                                            Edit
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    name="access"
                                    label="Access"
                                    rules={[{ required: true, message: 'Please select access' }]}
                                >
                                    <Radio.Group>
                                        <Radio value="Blocked">Blocked</Radio>
                                        <Radio value="Allow">Allow</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                        <RenderIfEntitled
                            entitlementToCheck={ADMIN_ENTITLEMENT}
                            distinctEntitlements={props.distinctEntitlements}
                            intl={props.intl}
                        >
                            <Row gutter={24} className={'pl20'}>
                                <Col span={8}>
                                    <Form.Item name="userId" label="User ID">
                                        <Select
                                            mode="tags"
                                            style={{ width: '100%' }}
                                            allowClear={true}
                                            placeholder="Enter user ID"
                                            onChange={(value) => onValueChangeToUpper("userId",value) }
                                            value={userIdList}
                                            onSelect={(value) => onSelect("userId",value)}
                                        >
                                            {userIdList.map((userId) => (
                                                <Select.Option key={userId} value={userId}>
                                                    {userId}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name="partner" label="Partner List">
                                        <Select
                                            mode="tags"
                                            style={{ width: '100%' }}
                                            allowClear={true}
                                            placeholder="Enter Partner list with"
                                            onChange={(value) => onValueChangeToUpper("partner",value) }
                                            value={partnerIdList}
                                            onSelect={(value) => onSelect("partner",value)}
                                        >
                                            {partnerIdList.map((partnerId) => (
                                                <Select.Option key={partnerId} value={partnerId}>
                                                    {partnerId}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </RenderIfEntitled>
                        <Row gutter={24} className={'pl20'}>
                            <Col span={8}>
                                <EntitlementAdvanceGroups
                                    intl={props.intl}
                                    entitlementTypes={entitlementTypes}
                                    selectedGroups={form.getFieldValue('entitlementGroup')}
                                    onValuesChange={onAdvanceGroupChange}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24} className={'pl20'}>
                            <Col span={24}>
                                <Form.Item name="ruleDescription" label="Rule Description">
                                    <Input.TextArea
                                        readOnly={true}
                                        value="Rule description"
                                        autoSize={{ minRows: 3, maxRows: 6 }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </Spin>
    );
};

export default CreateEntitlement;


