import { gql } from "@apollo/client";

export const SELECT_OPTION_QUERY = gql`
    query selectOption($select: String!, $username: String!) {
        getSelectDropDown(select: $select, username: $username) {
            id
            name
            value
        }
    }
`;

export const SELECT_OPTION_CO_CODE_ENTITLEMENT_QUERY = gql`
    query selectOptionEntitlement($uid: String!, $entitlement: String!) {
        getSelectDropDownFromCoCodeEntitlement(uid:$uid, entitlement:$entitlement) {
            id
            name
            value
        }
    }
`;

export const ALL_PARTNER_FOR_USER_QUERY = gql`
    query allPartnersForUser($userId: Float!) {
        allPartnersForUser(userId: $userId) {
            partnerId
            partnerNo
            partnerName
            category
        }
    }
`;

export const ALL_PARTNER_BY_PARTNER_NO = gql`
    query allPartnersByPartnerNo($partnerNo: String!) {
        allPartnersByPartnerNo(partnerNo: $partnerNo) {
            partnerId
            partnerNo
            partnerName
            companyId
            companyCode
            companyName
            roleId
            roleName
            systemId
            systemName
            systemCode
            companyPartnerId
        }
    }
`;

export const USER_LIST = gql`
    query getPortalUsers($portalUserInput: PortalUserInput!) {
        getPortalUser(portalUserInput: $portalUserInput) {
            user_id
            username
            firstname
            lastname
            email
            roleName

        }
    }
`;

export const ALL_PORTAL_USERS = gql`
    query getAllPortalUsers {
        getAllPortalUsers {
            user_id
            role_id
            username
            firstname
            lastname
            email
            roleName
            insert_time

        }
    }
`;

export const SEARCH_PORTAL_USERS = gql`
    query searchPortalUsers($portalUserInput: PortalUserInput!) {
        searchPortalUsers(portalUserInput: $portalUserInput) {
            userId
            roleId
            userName
            firstName
            lastName
            email
            objectClass
            employeeType

        }
    }
`;

export const ADMIN_PARTNER_LIST = gql`
    query getPartnerList($userId: Float!) {
        getPartnerForUser(userId: $userId) {
            partnerId
            partnerName
            partnerNo

        }
    }
`;

export const ALL_PROCESS_CODE_QUERY = gql`
    query allProcessCode {
        allProcessCode {
            id
            name
            value
        }
    }
`;

export const PROCESS_CODE_BY_USER_QUERY = gql`
    query allProcessCodeByUser($userId: Float!) {
        allProcessCodeByUser(userId: $userId) {
            processCodeId
            processCode
            processName

        }
    }
`;

export const COMPANY_CODE_BY_USER_QUERY = gql`
    query allCompaniesForUser($userId: Float!) {
        allCompaniesForUser(userId: $userId) {
            companyId
            companyCode
            companyName
            roleId
            roleName
            systemId
            systemName
            systemCode

        }
    }
`;


export const COMPANY_CODE_BY_PARTNER_QUERY = gql`
    query allCompaniesByPartnerNumber($partnerno: String!,$rolename: String! ) {
        allCompaniesByPartnerNumber(partnerno: $partnerno,rolename: $rolename ) {
            companyId
            companyCode
            companyName
            roleId
            roleName
            systemId
            systemName
            systemCode
        }
    }
`;

export const ASSIGN_USER_RIGHTS = gql`
    mutation mutateAssignedUserRights($userId: Float!,$userRights: [UserRightsInput!]! ) {
        mutateAssignedUserRights(userId: $userId, userRights: $userRights) {
            rowno
            userId
            userName
            partnerId
            partnerNo
            partnerName
            roleName
            roleId
            processCodeId
            processCode
            processCodeName
            companyLinkExists
            companyCode
            companyName
            companyId
            systemCompanyId
            systemId
            systemCode
            systemName
            companyPartnerId
            assigned
        }
    }
`;

export const USER_PREFERENCES = gql`
    query PreferencesGetAPI($preference: String!) {
        userPreferences(preference: $preference) {
            id
            code
            description
        }
}`;

export const ALL_ASSIGN_USER_RIGHTS = gql`
    query getAllUserRights($userId: Float!) {
        getAllUserRights(userId: $userId) {
            rowno
            userId
            userName
            partnerId
            partnerNo
            partnerName
            roleName
            roleId
            processCodeId
            processCode
            processCodeName
            companyLinkExists
            companyCode
            companyName
            companyId
            systemCompanyId
            systemId
            systemCode
            systemName
            companyPartnerId
            assigned
            insertTime
            insertUser
        }
    }
`;


export const VALIDATE_USER = gql`
    query validateUser {
        validateUser {
            portalUser{
                user_id
                role_id
                username
                firstname
                lastname
                email
                roleName
                realm
                uid
                departmentnumber
                roles
            }
            userPreferences {
                language
                decimalSeparator
                thousandSeparator
                dateFormat
                userSettingsId
            }
            success
            errorCode
            errorDescription
        }
    }
`;


export type AssignRights = {

    rowno?: number;
    userId?: number;
    userName?: string;
    partnerId?: number;
    partnerNo?: string;
    partnerName?: string;
    roleName?: string;
    roleId?: number;
    processCodeId?: number;
    processCode?: string;
    processCodeName?: string;
    companyLinkExists?: number;
    companyCode?: string;
    companyName?: string;
    companyId?: number;
    systemCompanyId?: number;
    systemId?: number;
    systemCode?: string;
    systemName?: string;
    companyPartnerId?: number;
    assigned?: number;
}

export const GET_PARTNER_LINKS= gql`
    query getPartnerLinks($entitlementPartnerInput: [EntitlementPartnerInput!]!) {
        getPartnerLinks(entitlementPartnerInput: $entitlementPartnerInput) {
            partnerNo
            partnerName
            partnerOu
            partnerId
            partnerInDB
            entitlements
            companies { companyCode companyName companyId}
            category
            appliedRuleIds
            details {
                    partnerNo
                    partnerName
                    partnerOu
                    partnerId
                    partnerInDB
                    entitlements
                    companies { companyCode companyName companyId}
                    category
                    appliedRuleIds
                    partnerNumberType
                }
         }
    }
`;
