import client from "../config/GraphQLApolloClient";
import {
    USER_ENTITLEMENT_BY_USERID,
    USER_ENTITLEMENT_BY_USERID_WITH_FILTER,
    USER_ENTITLEMENT_FOR_SELF_BY_COMPANY_CODE_AND_FILTER,
    USER_COMPANIES_BY_ENTITLEMENT,
    USER_ENTITLEMENT_FOR_SELF_WITH_FILTER,
    EDP_USER_PARTNERS_AND_COMPANIES_BY_USERID_AND_ENTITLEMENT
} from "./queries";
import {getUserEntitlementResultByUserId} from "./__generated__/getUserEntitlementResultByUserId";
import {
    getUserEntitlementResultByUserIdAndFilter,
    getUserEntitlementResultByUserIdAndFilter_getUserEntitlementResultByUserIdAndFilter_partners
} from "./__generated__/getUserEntitlementResultByUserIdAndFilter";
import {getSelfEntitlementResultByFilter} from "./__generated__/getSelfEntitlementResultByFilter";
import {
    getPartnersByCompanyCodesAndFilter,
    getPartnersByCompanyCodesAndFilter_getPartnersByCompanyCodesAndFilter as Partner
} from "./__generated__/getPartnersByCompanyCodesAndFilter";
import {
    getCompaniesByEntitlement,
    getCompaniesByEntitlement_allCompaniesForEntitlement as Company
} from "./__generated__/getCompaniesByEntitlement";
import {
    EDPPartnerAndCompany,
    EDPPartnerAndCompany_getUserEntitlementResultByUserIdAndEntitlement_partners,
    EDPPartnerAndCompanyVariables
} from "./__generated__/EDPPartnerAndCompany";

const logAuthUrl = () => {
  if (localStorage.getItem("authRedirectURL") !== null && localStorage.getItem("authRedirectURL") !== undefined)
  {
    return localStorage.getItem("authRedirectURL");
  }
  return "";
}

export const entitlementLookup = (userId: string) => {
  logAuthUrl();
    return new Promise<getUserEntitlementResultByUserId>((resolve, reject) => {
        client.query<getUserEntitlementResultByUserId>({
          query: USER_ENTITLEMENT_BY_USERID,
          variables: { userId, authentication: localStorage.getItem('id_token'), authRedirectURL: logAuthUrl()  },
          fetchPolicy: "cache-first"
        })
          .then((response) => {
            const finalResponse: getUserEntitlementResultByUserId = { getUserEntitlementResultByUserId: response.data.getUserEntitlementResultByUserId };
            resolve(
              finalResponse
            );
          })
          .catch((err) => {
            console.error(err);
            reject(err);
          });
      });
  }


export const filteredEntitlementLookup = (userId: string, entitlement: string, partnerName: String) => {
    return new Promise<getUserEntitlementResultByUserIdAndFilter>((resolve, reject) => {
        if(partnerName === null || partnerName === undefined || partnerName.length < 3) {
            reject("please enter a valid partner name to filter on - at least 3 characters");
        }
        else
        {
            client.query<getUserEntitlementResultByUserIdAndFilter>({
            query: USER_ENTITLEMENT_BY_USERID_WITH_FILTER,
              variables: { userId, entitlement, partnerName, authentication: localStorage.getItem("id_token")},
              fetchPolicy: "cache-first"
            })
              .then((response) => {
                const finalResponse: getUserEntitlementResultByUserIdAndFilter = { getUserEntitlementResultByUserIdAndFilter: response.data.getUserEntitlementResultByUserIdAndFilter };
                resolve(
                  finalResponse
                );
              })
              .catch((err) => {
                console.error(err);
                reject(err);
              });
        }
    });
}

export const filteredEntitlementLookupForSelf = (entitlement: string, partnerName: String, pageNumber?: number, pageSize?: number) => {
  return new Promise<getSelfEntitlementResultByFilter>((resolve, reject) => {
    if (partnerName === null || partnerName === undefined || partnerName.length < 3) {
      reject("please enter a valid partner name to filter on - at least 3 characters");
    } else {
      client.query<getSelfEntitlementResultByFilter>({
        query: USER_ENTITLEMENT_FOR_SELF_WITH_FILTER,
        variables: { entitlement, partnerName, logAuthUrl: logAuthUrl(), pageNumber, pageSize },
          fetchPolicy: "cache-first"
          //fetchPolicy: "network-only"
      })
        .then((response) => {
          const finalResponse: getSelfEntitlementResultByFilter = { getSelfEntitlementResultByFilter: response.data.getSelfEntitlementResultByFilter };
          resolve(
            finalResponse
          );
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    }
  });
};

export const partnerLookupByCompanyCodeAndPartnerFilter = (entitlement: string, partnerFilter: String, companyCodes:string[], pageNumber?: number, pageSize?: number) => {
    return new Promise<Partner[]>((resolve, reject) => {
        if (partnerFilter === null || partnerFilter === undefined || partnerFilter.length < 3) {
            reject(`please enter a valid partner name to filter on - at least 3 characters. Provided filter[${partnerFilter}]`);
        } else {
            client.query<getPartnersByCompanyCodesAndFilter>({
                query: USER_ENTITLEMENT_FOR_SELF_BY_COMPANY_CODE_AND_FILTER,
                variables: { entitlement, partnerFilter,companyCodes, pageNumber, pageSize },
                fetchPolicy: "cache-first"
            })
                .then((response) => {
                    resolve(response.data.getPartnersByCompanyCodesAndFilter)
                })
                .catch((err) => {
                    console.error(err);
                    reject(err);
                });
        }
    });
};

export const externalPartnersLookupByEntitlement = (userId:string,entitlement: string,pageNumber: number, pageSize: number) => {
    return new Promise<EDPPartnerAndCompany_getUserEntitlementResultByUserIdAndEntitlement_partners[]>((resolve, reject) => {
        client.query<EDPPartnerAndCompany,EDPPartnerAndCompanyVariables>({
            query:  EDP_USER_PARTNERS_AND_COMPANIES_BY_USERID_AND_ENTITLEMENT,
            variables: { userId, entitlement, pageNumber, pageSize },
            fetchPolicy: "cache-first"
        })
            .then((response) => {
                resolve(response.data.getUserEntitlementResultByUserIdAndEntitlement.partners)
            })
            .catch((err) => {
                console.error(err);
                reject(err);
            });
    });
};

export const companiesLookupByEntitlement = (entitlement: string) => {
    return client.query<getCompaniesByEntitlement>({
        query: USER_COMPANIES_BY_ENTITLEMENT,
        variables: { entitlement},
        fetchPolicy: "cache-first"
    }).then((response) => response.data.allCompaniesForEntitlement);
};

export const entitlementExists = (entitlementToFinds: string|string[], entitlements: string[]): boolean => {

    function checkEntitlements(entitlementToFind:string) {
        if (entitlementToFind === "*" || entitlementToFind === "") {
            return true;
        }
        if (entitlementToFind === null || entitlementToFind === undefined || entitlements === null || entitlements === undefined || entitlements.length === 0) {
            return false;
        }
        const endsWith = entitlementToFind.startsWith("*");
        const entitlementToMatch = entitlementToFind.replace("*", "");
        const wildcardRegex = (entitlementToFind.indexOf("*") > 0) ? new RegExp(entitlementToFind.toLowerCase().replace("*", ".+")) : null;
        const matchingEntitlements = entitlements.filter((value, index, array) => {
            if (value === undefined || value === null) {
                return false;
            }
            if (endsWith) {
                return value.endsWith(entitlementToMatch);
            } else if (wildcardRegex) {
                return wildcardRegex.test(value?.toLowerCase());
            }

            return value === entitlementToMatch;
        });
        return matchingEntitlements.length > 0;
    }

    if (Array.isArray(entitlementToFinds)) {

        for (const entitlementToFind of entitlementToFinds) {
            if (checkEntitlements(entitlementToFind)) {
                return true;
            }
        }

        return false;
    }

    return checkEntitlements(entitlementToFinds);

}

  export const hasEntitlement = (userId: string, entitlement: string) => {
    return new Promise<boolean>((resolve, reject) => {
        entitlementLookup(userId)
            .then((response) => {
              const findEntitlement = response.getUserEntitlementResultByUserId.distinctEntitlements.includes(entitlement);
              return resolve(findEntitlement);
            })
            .catch((err) => {
              console.error(err);
            reject(false);
          }
        );
      }
    )};


  export const hasEntitlementForPartner = (userId: string, entitlement: string, partnerName: String) => {
    return new Promise<boolean>((resolve, reject) => {
      filteredEntitlementLookup(userId,entitlement,partnerName)
            .then((response) => {
              const findEntitlement = response.getUserEntitlementResultByUserIdAndFilter.distinctEntitlements.includes(entitlement);
              return resolve(findEntitlement);
            })
            .catch((err) => {
              console.error(err);
            reject(false);
          }
        );
      }
    )};

  export const getPartnersForEntitlement = (userId: string, entitlement: string) => {
    return new Promise<getUserEntitlementResultByUserIdAndFilter_getUserEntitlementResultByUserIdAndFilter_partners[]>((resolve, reject) => {
      entitlementLookup(userId)
            .then((response) => {
              const findEntitlement = response.getUserEntitlementResultByUserId.distinctEntitlements.find((item) => {
                return item.trim().toLocaleLowerCase().endsWith(entitlement.trim().toLocaleLowerCase())});
              if (findEntitlement !== undefined)
              {
                resolve(response.getUserEntitlementResultByUserId.partners);
              }
              return resolve(undefined);
            })
            .catch((err) => {
              console.error(err);
            reject(undefined);
          }
        );
      }
    )};

  export const getPartnersForEntitlementAndCompanyCode = (userId: string, entitlement: string, companyCode: String) => {
    return new Promise<getUserEntitlementResultByUserIdAndFilter_getUserEntitlementResultByUserIdAndFilter_partners[]>((resolve, reject) => {
      entitlementLookup(userId)
            .then((response) => {
              const findEntitlement = response.getUserEntitlementResultByUserId.distinctEntitlements.find((item) => {
                return item.trim().toLocaleLowerCase().endsWith(entitlement.trim().toLocaleLowerCase())});
              //Only search company if the entitlement is part of the distinct entitlements
              if (findEntitlement !== undefined)
              {
                const partners = response.getUserEntitlementResultByUserId.partners.filter((partner) => 
                  partner.companies !== null && partner.companies.length > 0 && partner.companies.filter((company) => 
                    company.companyCode === companyCode).length > 0);
                return resolve(partners);
              }
              return resolve(undefined);
            })
            .catch((err) => {
              console.error(err);
            reject(undefined);
          }
        );
      }
    )};

    type CompanyCodes = {
      companyCode: string,
      companyName: string
    }





